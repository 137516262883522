import React, { useMemo, useState } from 'react';
import {
  CandidateLayout,
  DEFAULT_OG_IMAGES,
  SEO_COMMON,
} from '../../layout/candidate/CandidateLayout';
import { NextSeo } from 'next-seo';
import Utils from '../../utils/Utils';
import { SSRPage } from '../../components/SSRPage/SSRPage';
import { LandingPageTopSection } from './components/LandingPageTopSection';
//import { GetHiredSection } from './components/GetHiredSection';
import { PopularSection } from './components/PopularSection';
import { LatestJobOpenings } from './components/LatestJobOpenings';
import { TopHiringCompanies } from './components/TopHiringCompanies';
import { ProfileBanner } from './components/ProfileBanner';
import { GetHunted } from './components/GetHunted';
import { TestimonialsSection } from './components/TestimonialsSection';
import { ExperienceSection } from './components/ExperienceSection';
import { ExploreSection } from './components/ExploreSection';
import { LandingPageTopSectionX } from './components/LamdingPageTopSectionX';

export const LandingPage: SSRPage<any> = (props) => {
  const [latestJobOpenings, setLatestJobOpenings] = useState<any[]>([...props.latestJobOpenings]);
  const exps = useMemo(() => {
    return {
      starter: props.expLevels.find((e) => e.name === 'Career Starter'),
      one: props.expLevels.find((e) => e.name === '1-2 year'),
      two: props.expLevels.find((e) => e.name === '2-5 years'),
      five: props.expLevels.find((e) => e.name === '5-10 years'),
      ten: props.expLevels.find((e) => e.name === '10-15 years'),
      fifteen: props.expLevels.find((e) => e.name === '15+ years'),
    };
  }, [props.expLevels]);
  return (
    <CandidateLayout
      className="overflow-x-hidden"
      title={
        <NextSeo
          canonical="https://www.careers360.lk/"
          title={`Jobs in Sri Lanka | Job Vacancies in ${Utils.moment().format(
            'YYYY',
          )} | Careers360`}
          description="Careers360 is the most popular jobs portal in Sri Lanka. Find top job vacancies in Sri Lanka & foreign employment opportunities for your career growth."
          additionalMetaTags={[
            {
              name: 'keywords',
              content:
                'Jobs in Sri Lanka, Job Vacancies, Job Vacancies at colombo, Sri Lanka job vacancies, Jobs vacancy Sri Lanka, Part time job in Sri Lanka, Online jobs Sri Lanka',
            },
          ]}
          openGraph={{
            title: `Jobs in Sri Lanka | Job Vacancies in ${Utils.moment().format(
              'YYYY',
            )} | Careers360`,
            description:
              'Careers360 is the most popular jobs portal in Sri Lanka. Find top job vacancies in Sri Lanka & foreign employment opportunities for your career growth.',
            images: DEFAULT_OG_IMAGES,
          }}
          {...SEO_COMMON}
        />
      }>
      <LandingPageTopSectionX />
      {/*<GetHiredSection itCount={220} healthCount={120} otherCount={1310} />*/}
      <PopularSection
        sectors={props.sectors}
        functions={props.jobFunctions}
        employers={props.topHiring}
      />
      <LatestJobOpenings
        jobs={latestJobOpenings}
        onSave={(jobId, save) => {
          setLatestJobOpenings(
            latestJobOpenings.map((j) => (j.jobPostId === jobId ? { ...j, saved: save } : j)),
          );
        }}
      />
      <TopHiringCompanies companies={props.topHiring} />
      <ProfileBanner />
      <GetHunted />
      <TestimonialsSection testimonials={props.testimonials} />
      <ExperienceSection {...exps} />
      <ExploreSection />
    </CandidateLayout>
  );
};
LandingPage.getInitialProps = async (connection, query) => {
  try {
    // return {};
    // if (connection.token) {
    //   return { dashboard: true };
    // }
    const landingPageData = await Utils.getLandingPageData();
    if (landingPageData) {
      return landingPageData;
    }
    return await connection.get(`home/landing`);
  } catch (e) {
    return null;
  }
};
