import React, { FC, useCallback, useRef } from 'react';
import { Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Button, Grid } from 'antd';
import Utils from '../../../utils/Utils';
import { ArrowLeftOutlined, ArrowRightOutlined, ClockCircleOutlined } from '@ant-design/icons';
import Com1 from '../../../public/company.png';
import Image from 'next/image';
import Link from 'next/link';
import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import { faBookmark as faBookmarkSolid } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Router from 'next/router';
import { useConnection, useUserInfo } from '../../../connection/Application';
import styleModule from '../LandingPage.module.less';
import { Heading } from '../../../components/Heading/Heading';

const styles: any = styleModule;

type JobOpeningCardProps = {
  jobPostId: string;
  logo: string;
  jobTitle: string;
  location: string;
  recruiter: string;
  time: number;
  saved: boolean;
  onSaved: (saved: boolean) => void;
};
const JobOpeningCard: FC<JobOpeningCardProps> = (props) => {
  const user = useUserInfo();
  const connection = useConnection();
  const save = useCallback(() => {
    if (!user) {
      localStorage.setItem(
        'BackUrl',
        `/jobs/${Utils.normalizeURIComponent(props.jobTitle)}/${props.jobPostId}`,
      );
      localStorage.setItem('BackQuery', JSON.stringify({}));
      Router.push({
        pathname: '/login',
      });
      return;
    }
    connection.post(`job-apply/${props.jobPostId}/save`).then();
    props.onSaved(true);
  }, [connection, props, user]);

  const unsave = useCallback(() => {
    if (!user) {
      localStorage.setItem('BackUrl', `/`);
      localStorage.setItem('BackQuery', JSON.stringify({}));
      Router.push({
        pathname: '/login',
      });
      return;
    }
    connection.post(`job-apply/${props.jobPostId}/unsave`).then();
    props.onSaved(false);
  }, [connection, props, user]);

  return (
    <div className="group relative bg-white p-[20px] flex flex-col border border-slate-200 rounded-xl border-solid hover:border-primary hover:shadow-[0_3px_5px_rgba(91,98,114,0.1)] hover:drop-shadow cursor-pointer min-h-[210px] justify-between xl:max-w-[400px]">
      <div className="w-full flex flex-col gap-2">
        <div className="flex w-full gap-[16px]">
          <Image
            width={40}
            height={40}
            className="w-[40px] h-[40px]"
            src={props.logo ?? Com1}
            alt="logo"
          />
          <div className="overflow-hidden w-[65%] lg:w-[75%]">
            <div className=" text=[#0F1828] text-sm leading-4  lg:text-lg medium whitespace-nowrap text-ellipsis overflow-hidden">
              {props.recruiter}
            </div>
            <div className="text-[#5B6272] text-sm leading-4  lg:text-md regular whitespace-nowrap text-ellipsis overflow-hidden">
              {props.location}
            </div>
          </div>
        </div>
        <div className="text-xl leading-7 lg:text-2xl lg:leading-8 font-medium mt-2 text-ellipsis max-h-[64px] min-h-[64px] overflow-hidden">
          {props.jobTitle}
        </div>
      </div>
      <div className="flex justify-between items-end">
        <div className=" flex font-base text-gray-500 pb-2 item-center">
          <ClockCircleOutlined style={{ fontSize: 20 }} />{' '}
          <div className={'ml-2'}>{Utils.dateSince(props.time)}</div>
        </div>
        <Link href={`/jobs/${Utils.normalizeURIComponent(props.jobTitle)}/${props.jobPostId}`}>
          <Button
            onClick={() => {
              localStorage.setItem(
                'BackUrl',
                `/jobs/${Utils.normalizeURIComponent(props.jobTitle)}/${props.jobPostId}`,
              );
            }}
            size="small"
            style={{ borderRadius: 6 }}
            id="landing-btn-apply-now"
            className="text-sm leading-5 md:w-28 text-primary bg-[#F1FCF7] border-[#C1DFCD] group-hover:border-[#F1FCF7] hover:bg-primary hover:!text-white">
            Apply Now
          </Button>
        </Link>
      </div>

      <Button
        onClick={() => (props.saved ? unsave() : save())}
        shape="circle"
        type="link"
        size={'small'}
        className="absolute right-4 top-5 text-primary hover:bg-[#ECEDF2]">
        <FontAwesomeIcon
          type="regular"
          width={14}
          style={{ fontSize: 20 }}
          icon={props.saved ? faBookmarkSolid : faBookmark}
        />
      </Button>
    </div>
  );
};

type Props = {
  jobs: JobOpeningCardProps[];
  onSave: (jobId: string, save: boolean) => void;
};

export const LatestJobOpenings: FC<Props> = (props) => {
  const swipe = useRef<SwiperRef>(null);
  const bp = Grid.useBreakpoint();
  return (
    <section className=" mt-10 px-8 md:px-4 lg:px-8 xl:px-0">
      <Heading className={'text-center mb-10 flex-col sm:flex-row items-center'} variant={2}>
        <span>
          Our <span className="text-primary">Latest</span>
        </span>
        <span className="ml-3">
          <span className="text-primary">Job </span> Openings
        </span>
      </Heading>
      <div className="relative">
        <Swiper
          className={styles.latestSwiper}
          ref={swipe}
          // install Swiper modules
          modules={[Pagination, Autoplay, A11y]}
          spaceBetween={50}
          slidesPerView={1}
          autoplay={{ delay: 5000 }}
          loop={true}
          breakpoints={{
            200: {
              slidesPerView: 1,
              spaceBetween: 40,
            },
            // when window width is >= 320px
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            // when window width is >= 480px
            1200: {
              slidesPerView: 'auto',
              spaceBetween: 40,
            },
            // 1600: {
            //   slidesPerView: 4,
            //   spaceBetween: 40,
            // },
          }}
          // pagination={{ clickable: true }}
          // scrollbar={{ draggable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}>
          {props.jobs.map((job) => (
            <SwiperSlide className="xl:max-w-[400px] py-1 mt-[-4px]" key={job.jobPostId}>
              <JobOpeningCard {...job} onSaved={(saved) => props.onSave(job.jobPostId, saved)} />
            </SwiperSlide>
          ))}
        </Swiper>
        <Button
          shape="circle"
          size={bp.xl ? 'middle' : 'small'}
          className="border-primary hidden md:block text-primary bg-white absolute top-[75px] left-[-24px] xl:left-[-35px] 2xl:left-[-58px] z-10"
          onClick={() => swipe.current?.swiper.slidePrev()}>
          <ArrowLeftOutlined />
        </Button>
        <Button
          shape="circle"
          size={bp.xl ? 'middle' : 'small'}
          className="border-primary hidden md:block text-primary bg-white absolute top-[75px] right-[-24px] xl:right-[-35px] 2xl:right-[-58px] z-10"
          onClick={() => swipe.current?.swiper.slideNext()}>
          <ArrowRightOutlined />
        </Button>
      </div>
      <div className="flex justify-center p-8 pt-10">
        <Link
          href={`/jobs?results=show`}
          onClick={() => {
            localStorage.setItem('BackUrl', '/profile');
          }}
          className="text-base leading-6 font-medium mt-[-4px] hover:underline">
          View All Jobs &nbsp;
          <ArrowRightOutlined className=" ml-10group-hover:duration-500 group-hover:ml-2" />
        </Link>
      </div>
    </section>
  );
};
