import React, { FC } from 'react';
import Image from 'next/image';
import Step1 from '../../../public/home2/hunted-step-1.svg';
import Step2 from '../../../public/home2/hunted-step-2.svg';
import Step3 from '../../../public/home2/hunted-step-3.svg';
import styleModule from '../LandingPage.module.less';
import classNames from 'classnames';
import { Heading } from '../../../components/Heading/Heading';

const styles: any = styleModule;

type Props = {
  image: any;
  step: number;
  title: string;
  subtitle: string;
};

const GetHuntedCard: FC<Props> = (props) => {
  return (
    <div className="flex justify-center lg:justify-start mx-2">
      <div className="flex flex-col items-start gap-8 max-w-[394px]">
        <div className={classNames(styles.stepBackground, 'p-2 text-md medium text-white')}>
          STEP {props.step}
        </div>
        <Image src={props.image} alt={'step'} height={207} />
        <div className="flex flex-col gap-2">
          <div className="font-medium text-[24px] max-sm:text-[18px]">{props.title}</div>
          <div className="text-[#5B6272] text-[16px]">{props.subtitle}</div>
        </div>
      </div>
    </div>
  );
};

export const GetHunted: FC = () => {
  return (
    <section className=" lg:px-[30px] xl:px-0 mb-24 mt-20">
      <Heading variant={2} className="text-center mb-10">
        Get <span className="text-primary">Headhunted</span> by Employers
      </Heading>
      <div className="grid grid-cols-1 md:grid-cols-3 justify-center gap-[40px] md:gap-[20px]">
        <GetHuntedCard
          image={Step1}
          step={1}
          title="Create Your Profile"
          subtitle="Show employers your skills, qualifications, and experience through your profile"
        />
        <GetHuntedCard
          image={Step2}
          step={2}
          title="Get Headhunted"
          subtitle="Employers will contact you even without you applying for jobs"
        />
        <GetHuntedCard
          image={Step3}
          step={3}
          title="Find Your Dream Career"
          subtitle="Watch interview requests start rolling in from employers"
        />
      </div>
    </section>
  );
};
