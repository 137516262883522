import React, { FC, useCallback, useState } from 'react';
import stylesModule from '../LandingPage.module.less';
import { Title } from '../../../components/Typogrphy/Title';
import { Text } from '../../../components/Typogrphy/Text';
import { Button, Tabs } from 'antd';
import Form, { FormSchema } from '../../../components/form/Form';
import Image from 'next/image';
import SearchImage from '../../../public/home/search.svg';
import LocationPin from '../../../public/home/map-pin-search.svg';
import chatImage from '../../../public/home/careers-home.png';
import { useRouter } from 'next/router';
import { useUnderConstruction } from '../../../connection/ApplicationContext';
import Utils from '../../../utils/Utils';
import { RightOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;
const styles: any = stylesModule;

const InputPrefix: FC<{ src: any; className?: string }> = (props) => {
  return (
    <div className={Utils.mergeClasses(styles.inputPlaceholderPrefix, props.className)}>
      <Image alt="input" src={props.src} width={20} />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};
const tabContent = ['Jobs', 'Courses'];
const JobScehema: FormSchema = [
  {
    id: 'title',
    type: 'SearchAutocomplete',
    layout: { lg: 20, xs: 24 },
    props: {
      placeholder: 'Search Job titles',
      size: 'large',
      className: 'homepage-search-input',
      triggerOnChange: true,
      prefix: <InputPrefix src={SearchImage} className={styles.searchIcon} />,
    },
  },
  // {
  //   id: 'title',
  //   type: 'autocomplete',
  //   layout: { md: 20, xs: 24 },
  //   props: {
  //     placeholder: 'Search Job titles',
  //     type: 'Job Title',
  //     allowClear: true,
  //   },
  // },
  {
    id: 'location',
    type: 'enum',
    layout: { lg: 20, xs: 24 },
    props: {
      placeholder: 'City',
      size: 'large',
      enumName: 'Location',
      className: styles.searchLocation,
      prefix: <InputPrefix src={LocationPin} className={styles.locationSearchIcon} />,
    },
  },
];

const CourseScehema: FormSchema = [
  {
    id: 'title',
    type: 'string',
    layout: { md: 20, xs: 24 },
    props: {
      placeholder: 'Search Course',
      size: 'large',
      prefix: <InputPrefix src={SearchImage} className={styles.searchIconX} />,
    },
  },
  {
    id: 'location',
    type: 'enum',
    layout: { md: 20, xs: 24 },
    props: {
      placeholder: 'City',
      size: 'large',
      enumName: 'Location',
      className: styles.searchLocation,
      prefix: <InputPrefix src={LocationPin} className={styles.locationSearchIcon} />,
    },
  },
];

export const LandingSearchForm: FC<Props> = (props) => {
  const [type, setType] = useState<string>('Jobs');
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const history = useRouter();
  const under = useUnderConstruction();

  const onSearch = useCallback(
    (data, type) => {
      // console.log({ data });
      // console.log({ type });
      if (type === 'Courses') {
        under();
        return;
      }
      const query: any = {};
      if (data.title !== undefined) {
        query.search = data.title.toLowerCase();
      }
      if (data.location !== undefined) {
        query.location = [data.location.toLowerCase()];
      }
      history.push({
        pathname: '/jobs',
        query: { ...query, results: 'show' },
      });
    },
    [history, under],
  );
  return (
    <Tabs
      defaultActiveKey="Jobs"
      type={'line'}
      className={styles.homeTopSectionTabs}
      onChange={(key) => setType(key)}>
      <TabPane tab={<div className={styles.tabPaneText}>Jobs</div>} key={'Jobs'}>
        <Form.Form
          form={form}
          className={styles.searchForm}
          onFinish={(values) => onSearch(values, 'Jobs')}>
          <Form.Items schema={JobScehema} />
          <Button
            className={styles.searchBtn}
            id={'btn-job-search'}
            size={'large'}
            type={'primary'}
            htmlType={'submit'}>
            <div className="flex gap-2 transition-all mx-[-32px] my-[-16px] px-[32px] py-[16px] justify-center">
              <span>Find Jobs</span>
              <RightOutlined />
            </div>
          </Button>
        </Form.Form>
      </TabPane>
      <TabPane tab={<div className={styles.tabPaneText}>Courses</div>} key={'Courses'}>
        <Form.Form
          form={form2}
          className={styles.searchForm}
          onFinish={(values) => onSearch(values, 'Courses')}>
          <Form.Items schema={CourseScehema} />
          <Button className={styles.searchBtn} size={'large'} type={'primary'} htmlType={'submit'}>
            <div className="flex gap-2 hover:gap-4 transition-all mx-[-32px] my-[-16px] px-[32px] py-[16px] justify-center">
              <span>Find Courses</span>
              <RightOutlined />
            </div>
          </Button>
        </Form.Form>
      </TabPane>
    </Tabs>
  );
};
