import React, { FC } from 'react';
import { LandingSearchForm } from './LandingSearchForm';
import styleModule from '../LandingPage.module.less';
import Link from 'next/link';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import FindYou from '../../../public/home2/find-you.svg';
import Image from 'next/image';
import { Heading } from '../../../components/Heading/Heading';

const styles: any = styleModule;

export const LandingPageTopSectionX: FC = () => {
  return (
    <section className="relative w-[117vw] 3xs:w-[110vw] 2xs:w-screen self-center flex justify-center mt-[-20px] bg-[#E7EEF1] text-[#050931]">
      <div className={styles.topSectionLeft} />
      <div className={styles.topSectionRight} />
      <div className="w-full max-w-[1600px] px-[20px] md:px-[30px] lg:px-[65px] py-[40px] flex sm:items-center z-10 flex-col md:flex-row lg:flex-row md:gap-6 lg:gap-16 gap-16 lg:gap-0">
        <div className="grow shrink z-10">
          <Heading variant={1}>
            Fast-track your <span className={`${styles.careerText} text-primary`}>Career</span>
          </Heading>
          <div className="xl:text-xl text-base leading-6 font-normal text-gray-500">
            Find your big break in our extensive career search.{' '}
            <Link
              href={'/jobs?results=show'}
              target="_blank"
              className="font-medium group hover:underline">
              Start Now{' '}
              <FontAwesomeIcon
                className="ml-1 w-3.5 group-hover:duration-500 group-hover:w-4"
                icon={faExternalLinkAlt}
              />
            </Link>
          </div>
          <LandingSearchForm />
        </div>
        <div className="grow shrink flex items-center justify-end z-0">
          <div className={styles.topRightDiv}>
            <div className="flex lg:absolute lg:top-[100px] lg:left-[20px] rounded-lg bg-white drop-shadow w-full md:w-[363px] lg:w-[468px] min-h-[288px] flex flex-col md:p-5 lg: p-5 gap-4 justify-center">
              <div className="flex gap-1 p-3 absolute top-0 left-0">
                <div className="w-[6px] h-[6px] rounded-full bg-[#FE6058]" />
                <div className="w-[6px] h-[6px] rounded-full bg-[#FFBC2D]" />
                <div className="w-[6px] h-[6px] rounded-full bg-[#2AC640]" />
              </div>
              <div className="absolute top-[24px] right-[0px] bg-[#F5F5F5] h-[1px] w-full"></div>
              <div className="flex gap-4 mt-2">
                <Image
                  className={'xl:w-[53.6px] xl:h-[69.24px] w-[31.98px] h-[41.31px]'}
                  src={FindYou}
                  alt={'Find You'}
                />
                <div className="flex flex-col gap-2">
                  <div className="text-2xl max-xs:text-xl font-semibold">
                    Let Employers <span className="text-primary">Find you!</span>
                  </div>
                  <div className="text-[#5B6272] text-base leading-6 font-normal">
                    Build your profile to get headhunted by employers
                  </div>
                </div>
              </div>
              <Link href={`/profile`}>
                <Button
                  className=" w-full border-0 text-xl max-xs:text-[18px] bg-gradient-to-l from-[#1FAA77] to-[#5CCD97] hover:mr-[-10px]"
                  type="primary">
                  {/*<div className="justify-left max-xs:justify-center flex gap-2 transition-all mx-[-32px] my-[-16px] px-[80px] py-[16px] w-[356px] hover:w-[366px] max-xs:w-[271px] max-xs:hover:w-[276px] max-[350px]:w-[251px] max-[350px]:hover:w-[256px]">*/}
                  <div className="text-xl leading-7 font-medium justify-left max-xs:justify-center block gap-2 transition-all mx-[-32px] my-[-16px] px-[80px] py-[16px] ">
                    <span className={'pr-3'}>Create Your Profile</span>
                    <RightOutlined />
                  </div>
                </Button>
              </Link>
              <div className="flex flex-col gap-1">
                <div className="text-[#495564] max-xs:text-base leading-6 font-normal">
                  Get notified about jobs matching your criteria
                </div>
                <Link
                  href={'/jobs?results=show'}
                  className="text-base leading-6 font-semibold  origin-top-left hover:scale-100 lg:hover:scale-125 transition-none lg:transition-all">
                  Create Job Alert{' '}
                  <FontAwesomeIcon
                    className="w-3 group-hover:duration-300 group-hover:w-4 ml-2"
                    icon={faExternalLinkAlt}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
