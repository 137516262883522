import React, { FC } from 'react';
import ExpIcon from '../../../public/home2/experience.svg';
import Image from 'next/image';
import { RightOutlined } from '@ant-design/icons';
import ExpStart from '../../../public/home2/exp-starter.svg';
import ExpOneTwo from '../../../public/home2/exp-1-2.svg';
import ExpThreeFive from '../../../public/home2/exp-3-5.svg';
import ExpFiveTen from '../../../public/home2/exp-5-10.svg';
import ExpTenFifteen from '../../../public/home2/exp-10-15.svg';
import ExpFifteenPlus from '../../../public/home2/exp-15.svg';
import Utils from '../../../utils/Utils';
import Link from 'next/link';
import { Heading } from '../../../components/Heading/Heading';

type Props = {
  starter: { id: string; count: number };
  one: { id: string; count: number };
  two: { id: string; count: number };
  five: { id: string; count: number };
  ten: { id: string; count: number };
  fifteen: { id: string; count: number };
};

const ExperienceSectionCard: FC<{ icon: string; title: string; count: string; id: string }> = (
  props,
) => {
  return (
    <Link href={`/jobs?results=show&experienceLevel=${props.id}`}>
      <div className="group bg-white py-[20px] px-[20px] flex gap-1 items-center justify-between w-full  md:w-auto border border-solid border-gray-200 rounded-lg hover:border-primary hover:drop-shadow hover:bg-[#E4F9F0] cursor-pointer transition-all duration-200">
        <div className="flex flex-col gap-1">
          <div className="text-lg leading-6 font-medium text-[#050931]">{props.title}</div>
          <div className="text-[#5B6272] text-base leading-6 font-normal">
            {props.count} Vacancies
            <RightOutlined />
          </div>
        </div>
        {/*<i*/}
        {/*  className={`${props.icon} text-[20px] text-gray-700 opacity-75 group-hover:opacity-100 group-hover:text-primary group-hover:translate-x-[-6px] transition-all duration-500`}*/}
        {/*/>*/}
      </div>
    </Link>
  );
};

export const ExperienceSection: FC<Props> = (props) => {
  return (
    <section className="mb-[50px] md:mb-[80px]">
      <Heading variant={2} className="text-center">
        What is Your <span className="text-primary">Experience?</span>
      </Heading>
      <div className="flex flex-col xl:flex-row justify-between items-center px-[20px] md:px-[35px] py-[35px] lg:pb-[60px] bg-[#F8F9FD] mt-[80px] max-sm:mt-[15px] gap-14 xl:gap-1 rounded-[10px]">
        <div className="max-w-[820px] grow shrink flex flex-col md:flex-row xl:flex-col  md:items-center xl:items-start items-start  gap-14 xl:gap-3">
          <Image src={ExpIcon} alt={'experience'} />
          <div className="max-w-[460px]">
            <Heading variant={2} className="text-left">
              Find <span className="text-primary">job opportunities</span> for all levels of
              experience.
            </Heading>
            <div className="text-base leading-6 font-normal text-[#5B6272]">
              Match job openings to your experience level.
            </div>
          </div>
        </div>
        <div className="md:border md:border-gray-400 md:drop-shadow md:rounded-[10px] bg-white p-[20px] md:p-[40px] grid grid-cols-1 md:grid-cols-3 gap-[15px] md:gap-[20px] w-full md:w-[600px] lg:w-[850px] xl:w-[700px] xl:my-[-80px] grow-0 shrink-0 md:max-h-[474px]">
          <ExperienceSectionCard
            title="Career Starter"
            count={Utils.formatNumber(props.starter?.count, 0)}
            icon="icon-exp-starter"
            id={props.starter?.id}
          />
          <ExperienceSectionCard
            title="1-2 Years"
            count={Utils.formatNumber(props.one?.count, 0)}
            icon="icon-exp-1-2"
            id={props.one?.id}
          />
          <ExperienceSectionCard
            title="3-5 Years"
            count={Utils.formatNumber(props.two?.count, 0)}
            icon="icon-exp-3-5"
            id={props.two?.id}
          />
          <ExperienceSectionCard
            title="5-10 Years"
            count={Utils.formatNumber(props.five?.count, 0)}
            icon="icon-exp-5-10"
            id={props.five?.id}
          />
          <ExperienceSectionCard
            title="10-15 Years"
            count={Utils.formatNumber(props.ten?.count, 0)}
            icon="icon-exp-10-15"
            id={props.ten?.id}
          />
          <ExperienceSectionCard
            title=">15 Years"
            count={Utils.formatNumber(props.fifteen?.count, 0)}
            icon="icon-exp-15"
            id={props.fifteen?.id}
          />
        </div>
      </div>
    </section>
  );
};
