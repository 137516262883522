import React, { FC } from 'react';
import stylesModule from '../LandingPage.module.less';
import Link from 'next/link';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Heading } from '../../../components/Heading/Heading';

const styles: any = stylesModule;

export const ProfileBanner: FC = () => {
  return (
    <section className="mb-10 mt-6">
      <div
        className={`${styles.profileBanner} relative flex flex-row-reverse items-center flex-wrap lg:flex-nowrap p-6 lg:pr-20`}>
        <div
          className={
            'w-[30px] h-[30px] absolute left-0 bottom-[335px] bg-[#F8F9FD] lg:hidden'
          }></div>
        <div className="max-w-[800px] min-h-[300px] lg:flex flex-col justify-center">
          <Heading className={'my-0'} variant={1}>
            One stop to plan, manage and land your{' '}
            <span className="text-primary">dream career</span>
          </Heading>
          <p className="text-lg leading-6 font-normal text-[#5B6272] mt-5 max-sm:mt-5">
            Find your dream job & earn from world leading brands. <br className={'lg:hidden'} />
            <Link className="group font-medium hover:underline" href={`/profile`}>
              Create profile now{' '}
              <FontAwesomeIcon
                className="w-3.5 ml-0.5 group-hover:duration-300 group-hover:w-4"
                icon={faExternalLinkAlt}
              />
            </Link>
          </p>
        </div>
        <div className="md:min-w-[468px] min-h-[300px]" />
      </div>
    </section>
  );
};
