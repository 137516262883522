import React, { FC, useEffect, useState } from 'react';
import Connection from '../../connection/Connection';
import { ConnectionApi } from '../../connection/ApplicationContext';
import { useConnection } from '../../connection/Application';
import { NextPageContext } from 'next';
import styles from '../../pages/auth/Auth.module.less';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export type SSRPage<T> = FC<T> & {
  getInitialProps: (connection: Connection | ConnectionApi, query?: any) => Promise<T | null>;
};

export const SSRPageProps = async <T = any,>(C: SSRPage<T>, context: NextPageContext) => {
  const connection = Connection.nextConnection(context);
  if (connection) {
    const value: any = await C.getInitialProps?.(connection, context.query);
    if (value?.redirect) {
      context.res?.writeHead(301, { Location: value.redirect?.destination });
    }
    return { value };
  }
  return { query: context.query };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SSRPageProvider = <T, P = T>(props: { props: any; SSRComp: SSRPage<T> }) => {
  const [value, setValue] = useState<any>(props.props.value);
  const connection = useConnection();
  const { SSRComp } = props;

  useEffect(() => {
    if (!value) {
      SSRComp.getInitialProps(connection, props.props.query)?.then((v: any) => setValue(v));
    }
  }, [SSRComp, connection, props, props.props.query, value]);
  return (
    <>
      {value ? (
        <SSRComp {...value} />
      ) : (
        <div className={styles.centerContent}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />
        </div>
      )}
    </>
  );
};
