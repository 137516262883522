import React, { FC } from 'react';
import { Button } from 'antd';
import Link from 'next/link';
import { Heading } from '../../../components/Heading/Heading';
import { RightOutlined } from '@ant-design/icons';
export const ExploreSection: FC = () => {
  return (
    <section className="flex flex-col sm:flex-row justify-between items-left sm:items-center bg-[#F8F9FD] p-[30px] md:p-[40px] border border-solid border-gray-200 rounded-xl gap-6 mt-5 md:mt-16">
      <div className="flex flex-col gap-1 lg:gap-4">
        <Heading variant={1} className="text-left lg:text-left">
          Explore{' '}
          <span className="text-primary">
            <br className={'sm:hidden'} />
            Careers360
          </span>{' '}
          Now!
        </Heading>
        <div className="text-[#5B6272] text-left  lg:text-left text-lg regular">
          We want to help you find your dream job and manage your career.
        </div>
      </div>
      <div>
        <Link href={'/jobs?results=show'} className="group justify-end">
          <Button
            className={'w-full flex  justify-center'}
            type="primary"
            size="large"
            style={{ height: 60, borderRadius: 8 }}>
            <div className="text-white text-xl leading-7 font-semibold px-8 flex items-center">
              Find Jobs
              <RightOutlined className={'ml-3'} />
            </div>
          </Button>
        </Link>
      </div>
    </section>
  );
};
